import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'fc-page-container',
  imports: [CommonModule],
  template: `
    <div class="page-container" [class.selected]="itemSelected">
      <div class="sidenav-container card">
        <ng-content select=".sidenav"></ng-content>
      </div>
      <div class="main-container">
        <ng-content select=".main"></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      @use 'var' as *;

      :host {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
      }

      .page-container {
        display: grid;
        grid-template-columns: 350px 1fr;
        background: $secondary-50;
        overflow: hidden;
        width: 100%;
        height: 100%;

        @media (max-width: $sm-size) {
          grid-template-columns: 1fr;
        }
      }

      .sidenav-container {
        overflow: hidden;
        height: 100%;
      }

      .main-container {
        height: 100%;
        padding: 30px;
        overflow: auto;

        @media (max-width: $sm-size) {
          width: 100%;
          padding: 0;
        }
      }

      @media (max-width: $sm-size) {
        .sidenav-container {
          display: block;
        }
        .main-container {
          display: none;
        }
        .selected {
          .main-container {
            display: flex;
            flex-direction: column;
          }

          .sidenav-container {
            display: none;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageContainerComponent {
  @Input() itemSelected: boolean;
}
